<script>
  import { onMount, onDestroy } from 'svelte';
  import throttle from 'lodash/throttle';
  import { LANGUAGE, LANGUAGE_STORAGE_KEY } from '../constants';
  import { embedWidget } from '../lib/common'

  let modalOverlay;
  let endOfContent;
  let modalContent;
  let language;

  $: language;

  export let visible = false;

  export function open() {
    visible = true;
  }

  function close() {
    visible = false;
  }

  function handleBlur(e) {
    if (e.target === modalOverlay) {
      close();
    }
  }

  let moreContentAvailable = false;

  $: {
    if(visible) {
      embedWidget({
        bookMethods: ['contact-information', 'login', 'login-facebook'],
        bookLayout: 'service-based',
        timesLayout: 'week',
        listingLayout: 'rows',
        language,
        topOffset: '0',
        preventAutoscroll: true,
        company: '08b32c4f-9ca9-449b-8c69-85773ac2093c',
        serviceIdsToShow: ['85682', '68187'],
        showNextAvailableTime: true,
        hideServiceImage: false,
        showEndTimeOnTimeslots: false,
        darkTheme: false,
        translations: [],
        inModal: true
      });
    }
  }

  onMount(() => {
    language = localStorage.getItem(LANGUAGE_STORAGE_KEY) || LANGUAGE;

    if(!language) return;    
  });

  let gradientHeight;
  let bokameraEmbeddedHeight;

  $: if (bokameraEmbeddedHeight && modalContent && modalContent.clientHeight) {
    gradientHeight = getGradientHeight();
  }

  const getModalScrollTop = ()  => {
    if (modalContent && modalContent.scrollTop) {
      return modalContent.scrollTop;
    }

    return 0;
  }

  const handleModalScroll = (evt) => {
    gradientHeight = getGradientHeight();
    modalScrollTop = getModalScrollTop();
  };

  let modalScrollTop = getModalScrollTop();

  function getGradientHeight(feather = 30) {
    return modalContent.clientHeight + modalContent.scrollTop - feather;
  }
  onDestroy(() => {
    visible = false;
  })
</script>

<svelte:window on:click={handleBlur} />

<section class="modal">
  <div class:visible class="modal-overlay" bind:this={modalOverlay}>
    <div class="modal-controls">
      <svg class="close-modal" on:click={() => close()} viewBox="0 0 12 12">
        <line x1="3" y1="3" x2="9" y2="9" />
        <line x1="9" y1="3" x2="3" y2="9" />
      </svg>
    </div>
    <div
      class="modal-content"
      bind:this={modalContent}
      on:scroll={throttle(handleModalScroll, 10)}
    >
      <div id="bokamera-embedded" bind:clientHeight={bokameraEmbeddedHeight} />
      <div class="end-of-content" bind:this={endOfContent} />
      <div
        class="scroll-gradient"
        class:m-fadeIn={moreContentAvailable}
        class:m-fadeOut={!moreContentAvailable}
        style={`
          height: ${bokameraEmbeddedHeight}px;
          background: linear-gradient(var(--color-white) ${modalScrollTop}px, transparent
           ${modalScrollTop + (modalScrollTop > 20 ? 20 : 0)}px), linear-gradient(transparent ${gradientHeight}px, var(--color-white) ${
          gradientHeight + 20
        }px);

        `}
      />
    </div>
  </div>
</section>

<style>
  :root {
    --color-white: hsl(0, 0%, 100%);
    --color-black: hsl(0, 0%, 6%);
    --color-light: hsl(206, 33%, 96%);
    --color-night: hsl(214, 100%, 10%);
    --color-blue: hsl(225, 100%, 60%);
    --color-orange: hsl(9, 100%, 57%);

    --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: -99;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
  }
  .modal-content::-webkit-scrollbar {
    width: 12px;
  }
  .modal-content::-webkit-scrollbar-track {
    background-color: Scrollbar;
    border-radius: 100px;
  }
  .modal-content::-webkit-scrollbar-thumb {
    background-color: ActiveCaption;
    border-radius: 100px;
  }

  .visible {
    z-index: 999;
    opacity: 1;
    visibility: visible;
  }
  .modal-content {
    position: relative;
    width: 80%;
    max-width: 981px;
    padding: 20px 40px;
    border: none;
    outline: none;
    border-radius: 10px;
    color: var(--color-black);
    background: var(--color-white);
    box-shadow: var(--shadow-large);
    overflow-y: auto;
  }
  .scroll-gradient {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: 0.3 all;
  }
  .m-fadeOut {
    transition: visibility 0s linear 300ms, opacity 300ms;
  }
  .m-fadeIn {
    transition: visibility 0s linear 0s, opacity 300ms;
  }
  .modal-controls {
    position: relative;
    width: 80%;
    z-index: 200;
    max-width: 981px;
  }
  .close-modal {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: #fff;
  }

  .close-modal:hover {
    transform: scale(1.05);
  }

  .close-modal line {
    stroke: #fab891;
    stroke-width: 1.4;
  }

  .close-modal:hover line {
    stroke: #f57224;
  }
  @media (max-width: 600px) {
    .modal-content {
      flex-basis: 100%;
      width: 100%;
      height: auto;
    }
    .modal-controls {
      align-self: flex-end;
    }
  }
</style>
